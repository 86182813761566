<template>
  <tr>
    <!-- Origem -->
    <td>
      <v-select  v-model="value.origin" class="md-form-control"
                 :name="name + '.origin'"
                 :placeholder="placeholder" label="nome" ref='filterField'
                 v-validate="{ required: true }"
                 :searchable="searchable" :clearable="false" :options="OriginDestinationList"
                 @search="originDestinationFetchOptions" @input="onSelectOrigin"
      >
        <template slot="no-options">
          {{ noOptions }}
        </template>
        <template slot="option" slot-scope="option">
          <div class="d-center" v-bind:class="{ 'option-disabled': isIncluded(option) }">
            {{ option.nome }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.nome }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="">
        <small v-if="!validOrigin" class="royalc-error-input">Campo obrigatório</small>
      </transition>
    </td>
    <!-- Destino -->
    <td>
      <v-select  v-model="value.destination" class="md-form-control" :placeholder="placeholder" label="nome" ref='filterField'  :searchable="searchable" :clearable="false" :options="OriginDestinationList" @search="originDestinationFetchOptions">
        <template slot="no-options">
          {{ noOptions }}
        </template>
        <template slot="option" slot-scope="option">
          <div class="d-center" v-bind:class="{ 'option-disabled': isIncluded(option) }">
            {{ option.nome }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.nome }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="">
        <small v-if="!validDestination" class="royalc-error-input">Campo obrigatório</small>
      </transition>
    </td>
    <td>
      <v-select label="code" class="md-form-control" :clearable="false" :searchable="false" v-model="value.tier"  :options="tierList" >
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.code }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.code }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="">
        <small v-if="!validTier" class="royalc-error-input">Campo obrigatório</small>
      </transition>
    </td>
    <td>
      <v-select label="name"  class="md-form-control" v-model="value.approach" :clearable="false" :searchable="false"  :options="strategyList" @input="onSelectApproach" >
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.name }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.name }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="">
        <small v-if="!validApproach" class="royalc-error-input">Campo obrigatório</small>
      </transition>
    </td>
    <!--  Opções por origem e destino  -->
    <td>
      <input class="md-form-control" v-show="!value.approach || value.approach.id === 1"  v-model="value.options_per_origin_destination" type="number" >
      <input class="md-form-control option-disabled" title="Opção indisponível para a estratégia selecionada" v-show="value.approach && value.approach.id !== 1"  disabled type="number" >
      <transition name="slide" mode="">
        <small v-if="!validOptionsPerOriginDestination" class="royalc-error-input">Campo obrigatório</small>
        <small v-else-if="!validOptionsPerOriginDestinationRange" class="royalc-error-input">Valor deve ser maior que 0</small>
      </transition>
    </td>
    <td>
      <input class="md-form-control" v-model="value.minimum_validity"   type="number" >
      <transition name="slide" mode="">
        <small v-if="!validMinimumValidity" class="royalc-error-input">Campo obrigatório</small>
        <small v-else-if="!validMinimumValidityRange" class="royalc-error-input">Valor deve ser maior que 0</small>
      </transition>
    </td>
    <!-- long_term -->
    <td v-if="showLongTerm">
      <label class="ui-switch no-margin small switch-solid switch-warning">
        <input tabindex="-1" :value="1" type="checkbox" ref="long_term" :checked="(value.long_term == 1)" v-on:change="toggleLongTerm"><span></span></label>
    </td>
    <!-- Receber -->
    <td>
      <label class="ui-switch no-margin small switch-solid switch-warning">
        <input tabindex="-1" :value="1" type="checkbox" ref="status" :checked="(value.status == 1)" v-on:change="toggleStatus"><span></span></label>
    </td>
    <!-- Action-->
    <td>
      <a class="btn btn-link btn-sm" @click="removeOriginDestination(rowIndex)" tabindex="">
        <i class="text-primary  ti-trash font-20"></i>
      </a>
      <a class="btn btn-link btn-sm" @click="addOriginDestination(rowIndex)" tabindex="">
        <i class="text-primary fa fa-plus-circle font-20"></i>
      </a>
    </td>
  </tr>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'OriginDestinationForm',
  props: {
    showLongTerm: {
      type: Boolean,
      required: false,
      default: false
    },
    rowIndex: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    OriginDestinationList: {
      type: Array,
      required: true
    },
    tierList: {
      type: Array,
      required: true
    },
    strategyList: {
      type: Array,
      required: true
    },
    originDestinationFetchOptions: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    removeOriginDestination: {
      type: Function,
      required: true
    },
    addOriginDestination: {
      type: Function,
      required: true
    },
    // Não utilizado \/
    enableFilter: {
      type: Boolean,
      default: true
    },
    enableStatus: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    apiUrl: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    idField: {
      type: String,
      default: 'id'
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    valueTransform: {
      type: Function,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    vSelect
  },
  created () {
    let _this = this
    _this.id = 'origin_destination_form_' + _this._uid
  },
  data () {
    return {
      submited: false,
      searchTerm: null,
      selectedValue: null
    }
  },
  methods: {
    onSelectApproach (val) {
      if (val) {
        if (val.id === 1) {
          this.value.options_per_origin_destination = 1
        } else {
          this.value.options_per_origin_destination = 3
        }
      }
    },
    toggleStatus (e) {
      this.value.status = ((e.target.checked) ? 1 : 2)
      this.$emit('input', { ...this.value })
    },
    toggleLongTerm (e) {
      this.value.long_term = ((e.target.checked) ? 1 : 2)
      this.$emit('input', { ...this.value })
    },
    onSelectOrigin (val) {
      this.value.tier_local = null
    },
    onRowClicked (dataItem, dataIndex, event) {
      this.$emit('rowClicked', { data: dataItem, index: dataIndex, event: event })
      return true
    },
    isIncluded: function (val) {
      return false
    },
    toggleSelectAll () {
      let activeStatus = this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      if (this.allSelected) {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = 2
          this.value.splice(i, 1, item)
        }
      } else {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = activeStatus
          this.value.splice(i, 1, item)
        }
      }
      this.$emit('toggleAll', this.value)
    },
    addSelectedOption (val) {
      let defaultStatus = 1
      if (this.enableStatus !== null) {
        defaultStatus = this.enableStatus
      }

      if (val != null) {
        if (this.valueTransform) {
          val = this.valueTransform(val)
        }

        if (!this.isIncluded(val)) {
          val.status = defaultStatus
          this.value.push(val)
          this.$emit('onInput', { data: val })
        }
        this.$refs.filterField.clearSelection()
      }
    },
    removeSelectedOption (val) {
      if (val != null) {
        let _this = this
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element[_this.idField] === val
        })
        // remove element from selected options
        this.value.splice(index, 1)
        this.$emit('onRemoved', val)
      }
    },
    validate (value) {
      let _this = this
      return new Promise(function (resolve, reject) {
        let valid = true

        // Origem
        if (!_this.validOrigin) {
          valid = false
        }

        // Destino
        if (!_this.validDestination) {
          valid = false
        }

        // Tier
        if (!_this.validTier) {
          valid = false
        }

        // Estratégia
        if (!_this.validApproach) {
          valid = false
        }

        // Opções
        if (_this.validApproach && _this.value.approach.id === 1 && !_this.validOptionsPerOriginDestination) {
          valid = false
        }

        if (_this.validApproach && _this.value.approach.id === 1 && !_this.validOptionsPerOriginDestinationRange) {
          valid = false
        }

        // Validade (dias)
        if (!_this.validMinimumValidity) {
          valid = false
        }

        if (!_this.validMinimumValidityRange) {
          valid = false
        }

        resolve(valid)
      })
    }
  },
  computed: {
    allSelected () {
      let _this = this
      if (_this.value.length <= 0) {
        return false
      }

      let activeStatus = _this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      let activeValues = _this.value.filter(function (x) {
        return x.status === activeStatus
      })

      if (activeValues.length === _this.value.length) {
        return true
      }

      return false
    },
    computedValue: function () {
      if (this.enableStatus === null) {
        return this.value
      }

      let _self = this

      return this.value.filter(function (status) {
        return status.status === _self.enableStatus
      })
    },
    validOrigin () {
      return this.value.origin
    },
    validDestination () {
      return this.value.destination
    },
    validTier () {
      return this.value.tier
    },
    validApproach () {
      return this.value.approach
    },
    validMinimumValidity () {
      return this.value.minimum_validity
    },
    validMinimumValidityRange () {
      let valid = true
      if (!Number.isInteger(parseInt(this.value.minimum_validity))) {
        valid = false
      }

      if (parseInt(this.value.minimum_validity) <= 0) {
        valid = false
      }

      return valid
    },
    validOptionsPerOriginDestination () {
      return this.value.options_per_origin_destination
    },
    validOptionsPerOriginDestinationRange () {
      let valid = true
      if (!Number.isInteger(parseInt(this.value.options_per_origin_destination))) {
        valid = false
      }

      if (parseInt(this.value.options_per_origin_destination) <= 0) {
        valid = false
      }

      return valid
    }
  }
}
</script>

<style scoped>
  .buttonClose:not(:disabled):not(.disabled) {
    cursor: pointer;
    font-size: 12px;
  }

  .option-disabled {
    cursor: not-allowed;
    color: #83dadb;
  }
</style>
